import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { serviceOptions, pointOwnerOptions, locales } from '@/mixins/options'
import router from '@/router'
import i18n from '@/libs/i18n'

export const usePointRemoteData = () => {
  const closeServiceRequest = (...args) => store.dispatch('point/closeServiceRequest', ...args)
  const fetchPointPurposes = (...args) => store.dispatch('point/fetchPointPurposes', ...args)
  return {
    closeServiceRequest,
    fetchPointPurposes,
  }
}
export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = productId => {
    return store.dispatch('app-ecommerce/addProductInWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = productId => {
    return store.dispatch('app-ecommerce/removeProductFromWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = productId => {
    return store.dispatch('app-ecommerce/addProductInCart', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => {
    return store.dispatch('app-ecommerce/removeProductFromCart', { productId })
  }

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
  }
}

export const usePointsUi = () => {
  const getOwnerProperty = pointData => {
    let options = null
    const point = pointData || store.getters['point/Point']
    if (point.managed) {
      options = pointOwnerOptions.find(option => option.managed === point.managed)
    }
    return options || pointOwnerOptions[0]
  }

  const userCanAddOffer = () => {
    const user = store.getters['account/user']

    if (!user.id) return true

    let userAbilities = user.ability

    if (!userAbilities && user.id) return false

    const { subscriptions } = user

    if (subscriptions && subscriptions.length) {
      const subscriptionAbilities = subscriptions.map(el => el.abilities).shift()

      if (subscriptionAbilities && subscriptionAbilities.length) {
        userAbilities = [...userAbilities, ...subscriptionAbilities]
      }
    }
    const createOfferAbilityIndex = userAbilities.findIndex(el => el.subject === 'ServiceOffer' && el.action === 'create')

    return (createOfferAbilityIndex > -1)
  }

  const mainImage = point => point.main_image && 'url' in point.main_image

  const defaultImage = require('@/assets/images/svg/service-noimage.svg')

  const getMainImage = point => (mainImage(point) ? point.main_image.url : defaultImage)

  const getFirstGalleryImage = point => (point.media_files !== undefined && point.media_files.length ? point.media_files[0].url : defaultImage)

  const getOptionPurposes = options => {
    // Add icons, tooltips etc
    const result = []
    options.forEach(obj => {
      const purpose = serviceOptions.purposes.find(({ slug }) => slug === obj.slug)
      result.push({ ...purpose, ...obj })
    })
    return result
  }

  const { router } = useRouter()

  const resolvePointTypeBadgeVariant = pointTypeSlug => (pointTypeSlug === 'offer' ? 'warning' : 'primary')
  const resolveCloseReasonColorVariant = pointCloseReason => (pointCloseReason === 'request_completed' ? 'success' : 'secondary')

  const isManaged = point => point.managed
  const isOnBehalf = point => point.managed !== 'undefined'

  const toggleProductInWishlist = product => {
    const { addProductInWishlist, removeProductFromWishlist } = useEcommerce()
    if (product.isInWishlist) {
      removeProductFromWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = false
      })
    } else {
      addProductInWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = true
      })
    }
  }

  const handleCartActionClick = product => {
    const { addProductInCart } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInCart = true

        // Update cart items count
        store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
      })
    }
  }

  const handleEditOfferActionClick = product => {
    router.push({ name: 'apps-service-offer-edit', params: { id: product.id } })
  }
  const handleEditRequestActionClick = product => {
    router.push({ name: 'apps-service-request-edit', params: { id: product.id } })
  }
  const handleWishlistCartActionClick = (product, removeProductFromWishlistUi) => {
    const { addProductInCart, removeProductFromWishlist } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(product.id)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInCart = true
          removeProductFromWishlist(product.id)

          // Update cart items count
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInWishlist = false
          removeProductFromWishlistUi(product)
        })
    }
  }
  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleEditOfferActionClick,
    handleEditRequestActionClick,
    handleWishlistCartActionClick,
    resolvePointTypeBadgeVariant,
    resolveCloseReasonColorVariant,
    getOptionPurposes,
    mainImage,
    getMainImage,
    getFirstGalleryImage,
    defaultImage,
    userCanAddOffer,
    isManaged,
    isOnBehalf,
    getOwnerProperty,
  }
}

export const useServicesQueryHandler = () => {
  const queryKey = 'q'

  const setQuery = value => localStorage.setItem(queryKey, JSON.stringify(value))
  const getQuery = () => JSON.parse(localStorage.getItem(queryKey))
  const removeQuery = () => localStorage.removeItem(queryKey)

  const serviceQueryHandler = () => {
    const {
      route,
      router,
    } = useRouter()

    const query = { }

    if ('tid' in route.value.query && 'pids' in route.value.query) {
      query.point_purposes = route.value.query.pids.split('&')
        .map(Number)
      query.point_type = parseInt(route.value.query.tid, 10)
    }

    if ('lang' in route.value.query) {
      const { lang } = route.value.query
      const { setLocale, getLang } = locales()
      if (getLang(lang)) {
        setLocale(lang)
      }
    }

    router.push({ name: 'pages-landing' }).then(() => {
      setQuery(query)
    })
  }
  const getServiceQueryLink = () => {
    const selectedPurposes = store.getters['point/filter']
    let props = ''

    if (selectedPurposes && selectedPurposes.point_type) {
      props = router.resolve({
        name: 'app-service-query',
        query: {
          tid: selectedPurposes.point_type,
          pids: selectedPurposes.point_purposes.join('&'),
          lang: i18n.locale,
        },
      })
    }
    return props ? window.location.origin + props.href : window.location.origin
  }
  return {
    serviceQueryHandler,
    getServiceQueryLink,
    getQuery,
    setQuery,
    removeQuery,
  }
}
